import React from "react"
import styles from './BuyAllButton.module.scss';
import cn from 'classnames';
import { showNotAuthorizedPopup } from "ui";

export const BuyAllButton = ({ showLogin, showNotAuthorized, showRequestQuotationByEmail, showContactSupport, showBuyButton, requestQuotationByEmail, contactSupportLink, buyAll }) => {
    const translations = window.app.preloadState.translation;
    const loginLink = window.app.preloadState.loginLink;

    if (showLogin)
        return <a className={styles.itemLogInLink} href={loginLink}>{translations["sparePartVariationButtonOptions.login"]}</a >

    if (showNotAuthorized)
        return <span className={cn(styles.buyButton, "btn btn--outlined", styles.buttonStyledAnchor)}
            title={translations["sparePartVariationButtonOptions.insufficientRightsToBuy"]}
            onClick={showNotAuthorizedPopup}>{translations["sparePartVariationButtonOptions.moreInformation"]}</span>

    if (showRequestQuotationByEmail)
        return <a className={styles.itemLogInLink} href={requestQuotationByEmail}>{translations["sparePartVariationButtonOptions.requestForQuotation"]}</a>

    if (showContactSupport)
        return <a className={styles.itemLogInLink} href={contactSupportLink}>{translations["sparePartVariationButtonOptions.contactSupport"]}</a>

    if (!showBuyButton)
        return <span></span>

    return <button className={cn(styles.buyButton, "btn btn--primary firstLetterCapital")} onClick={buyAll}>{translations["sparePartItem.buyAll"]}</button>
}